@use "sass:map";
@use "variables";

$breakpoints: (
  'sm': 'screen and (max-width: 540px)',
  'md': 'screen and (max-width: 768px)',
  'mm': 'screen and (max-width: 896px)',
  'lg': 'screen and (max-width: 1024px)',
  'xl': 'screen and (max-width: 1300px)',
) !default;

@mixin mq($breakpoint: md) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}

@mixin breakpoint-sm {
  @media screen and (min-width: 0px) and (max-width: (variables.$sp-side)) {
    @content;
  }
}
@mixin breakpoint-md {
  @media screen and (min-width: 0px) and (max-width: (variables.$sp-length)) {
    @content;
  }
}
@mixin breakpoint-lg {
  @media screen and (min-width: 0px) and (max-width: (variables.$tab-length)) {
    @content;
  }
}
@mixin breakpoint-xl {
  @media (min-width: (variables.$pc-length)) {
    @content;
  }
}

@mixin display-flex($items, $content) {
  display: flex;
  align-items: $items;
  justify-content: $content;
}

/*コンテンツ幅*/
@mixin container() {
  width: 90%;
  max-width: variables.$max-width;
  margin: 0 auto;
}

@mixin absolute($width, $height) {
  position: absolute;
  content: "";
  width: $width;
  height: $height;
}

/* font */
