@use "../global";

#pagination {
  font-size: 1.4rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 48px;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    li {
      a {
        display: block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
      }
    }
  }
  .material-icons {
    display: block;
    color: #999;
    line-height: 24px;
    width: 24px;
    height: 24px;
    text-align: center;
  }
}
