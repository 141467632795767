@use "../global";

#footer {
  background-color: #1A1B01;
  img {
    display: inline-block;
  }
}

#footer_logo {
  @include global.mq(md) {
    text-align: center;
  }
}

#footer_container {
  padding-top: 48px;
  padding-bottom: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include global.mq(md) {
    display: block;
    padding: 48px 24px;
  }
  a {
    color: #FFF;
  }
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 48px;
    font-size: 1.4rem;
    @include global.mq(md) {
      margin-top: 24px;
      justify-content: center;
    }
  }
  address {
    display: block;
    font-size: 1.2rem;
    line-height: 1;
    color: #FFF;
    margin-top: 12px;
    @include global.mq(md) {
      margin-top: 24px;
    }
  }
}
