@use "../global";

#home_carousel_container {
  padding-top: 48px;
  padding-bottom: 48px;
  @include global.mq(md) {
    padding-top: 0;
  }
}

#home_carousel {
  width: global.$pc_width;
  margin-left: auto;
  margin-right: auto;
  @include global.mq(md) {
    width: auto;
  }
  .swiper-slide {
    height: 432px;
    @include global.mq(md) {
      height: 230px;
    }
    img {
      display: block;
      object-position: center;
      object-fit: cover;
      width: 100%;
      height: 432px;
      @include global.mq(md) {
        height: 230px;
      }
    }
  }
  .swiper-pagination {
    margin-top: 24px;
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
  }
  .swiper-pagination-bullet {
    display: block;
    width: 12px;
    height: 12px;
    margin-left: 0;
    margin-right: 0;
  }
  .swiper-pagination-bullet-active {
    background-color: global.$color-yellow;
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 30px;
    height: 30px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    line-height: 1;

  }
  .swiper-button-next:after {
    font-family: 'Material Icons';
    content: "\e409";
    color: black;
    font-size: 2.4rem;
  }
  .swiper-button-prev:after {
    font-family: 'Material Icons';
    content: "\e408";
    color: black;
    font-size: 2.4rem;
  }
}

#home_news {
  padding-top: 48px;
  padding-bottom: 48px;
  @include global.mq(md) {
    padding-top: 36px;
    padding-bottom: 36px;
  }
}

#home_works_header,
#home_news_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
  h2 {
    margin-bottom: 0;
  }
  .more {
    font-size: 1.4rem;
    a {
      display: inline-flex;
      align-items: center;
      column-gap: 10px;
      &::before {
        font-family: 'Material Icons';
        content: "\e409";
        font-size: 1.4rem;
      }
    }
  }
}

#home_about {
  background-color: #eee223;
  background-image: url(../img/bg_home_about.webp);
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
  padding-top: 80px;
  padding-bottom: 80px;
  @include global.mq(md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    column-gap: 60px;
    @include global.mq(md) {
      display: block;
    }
    #section_h2 {
      margin-bottom: 60px;
      @include global.mq(md) {
        margin-bottom: 30px;
      }
    }
    .about_us {
      flex: 1;
    }
    .about_images {
      @include global.mq(md) {
        margin-top: 30px;
      }
      img {
        @include global.mq(md) {
          width: 100%;
        }
      }
    }
    .about_us p {
      font-size: 1.6rem;
      line-height: 2;
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
      @include global.mq(md) {
        font-size: 1.4rem;
        &:not(:last-of-type) {
          margin-bottom: 14px;
        }
      }
    }
  }
  #home_about_links {
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 24px;
    @include global.mq(md) {
      margin-top: 30px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      row-gap: 12px;
    }
    a {
      display: flex;
      font-size: 1.4rem;
      align-items: center;
      height: 26px;
      background-color: #fff;
      border-radius: 26px;
      padding-left: 12px;
      padding-right: 12px;
      &::before {
        font-family: 'Material Icons';
        content: "\e409";
        font-size: 1.4rem;
      }
      span {
        display: block;
        flex: 1;
      }
    }
  }
}
