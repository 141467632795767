/*--------------------------------------------------------------
# Setting
--------------------------------------------------------------*/

$pc_width: 1152px;

/* font */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');


/* color */
$color-red: #FF8080;
$color-dark: #3B4043;
$color-light: #F0F3F5;
$color-yellow: #FFEF00;
$color-light-yellow: #F5F1B0;

/* color-text */
$color-line: #707070;

$sp-side: 576px; // 小デバイス（横向きモバイル, 576px 以上）
$sp-length: 768px; // 中デバイス（タブレット, 768px 以上）
$tab-length: 992px; // 大デバイス（デスクトップ, 992px 以上）
$pc-length: 1200px; // 特大デバイス（ワイド・デスクトップ, 1200px 以上）
$max-width: 1080px; // コンテンツ最大幅
