@use "../global";

#header_space {
  height: 96px;
  @include global.mq(md) {
    height: 60px;
  }
}

#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  &.noShadow {
    box-shadow: none;
  }
}

#header-container {
  height: 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include global.mq(md) {
    height: 60px;
  }
  img {
    display: block;
    @include global.mq(md) {
      width: 143.25px;
    }
  }
}

.global-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 400;
  column-gap: 40px;
  font-family: 'Raleway', sans-serif;
  @include global.mq(md) {
    display: none;
  }
}

#menu_btn {
  display: none;
  @include global.mq(md) {
    display: block;
  }
  #open_button {
    display: block;
  }
  #close_button {
    display: none;
  }
}
#menu_btn.open {
  #open_button {
    display: none;
  }
  #close_button {
    display: block;
  }
}

#overray_header {
  padding-top: 60px;
	position: fixed;
	top: 0;
	left: 0;
	background-color: #fff;
	overflow: hidden;
	z-index: 8;
	opacity: 0;
	width: 0;
	transition: opacity 0.6s;
	&.active {
    opacity: 1;
    visibility: visible;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
		@include global.mq(md) {
			opacity: 1;
			visibility: visible;
			width: 100%;
			height: calc(var(--vh, 1vh) * 100);
		}
	}
  .global-nav {
    display: block;
    padding-bottom: 40px;
    border-bottom: 1px solid #CCC;
    li {
      font-family: 'Raleway', sans-serif;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  .sub-nav {
    padding-top: 40px;
    li {
      font-size: 1.4rem;
      line-height: 1;
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
}

#overray_header_menu {
  padding: 36px 24px;
}
