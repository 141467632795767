#breadcrumbs {
  .container {
    display: flex;
    align-items: center;
    column-gap: 12px;
    height: 40px;
    font-size: 1.2rem;
    line-height: 1;
    a {
      display: inline-flex;
      align-items: center;
      column-gap: 12px;
      white-space: nowrap;
      &::after {
        font-family: 'Material Icons';
        content: "\e409";
        font-size: 2rem;
        color: #999;
      }
    }
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .material-icons {
    font-size: 2rem;
  }
}
