@use "../global";

#news {
  padding-top: 60px;
  padding-bottom: 60px;
  @include global.mq(md) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

#news_container {
  margin-top: 48px;
  margin-bottom: 48px;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  column-gap: 24px;
  row-gap: 48px;
  flex-wrap: wrap;
  @include global.mq(md) {
    row-gap: 0;
  }
}

#page_body_container,
.news_body_container {
  width: 860px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px;
  &.head {
    padding-bottom: 0;
  }
  &.body {
    padding-top: 0;
  }
  @include global.mq(md) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

#news_header {
  h1 {
    font-size: 2.8rem;
    line-height: 1.5;
    text-align: left;
    @include global.mq(md) {
      font-size: 2rem;
    }
  }
  .news_meta {
    margin-top: 48px;
    margin-bottom: 48px;
    @include global.mq(md) {
      margin-top: 36px;
      margin-bottom: 36px;
    }
  }
}

#news_thumbnails {
  width: 860px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 48px;
  @include global.mq(md) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 36px;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}

#page_header {
  margin-bottom: 48px;
  @include global.mq(md) {
    margin-bottom: 36px;
  }
}

.news_meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include global.mq(md) {
    display: block;
  }
  aside {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1;
    column-gap: 48px;
    @include global.mq(md) {
      justify-content: space-between;
    }
    date {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 6px;
      color: #999;
      font-size: 1.4rem;
      &::before {
        font-family: 'Material Icons';
        content: "\e8b5";
        font-size: 2rem;
      }
    }
    .category {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 24px;
      @include global.mq(md) {
        column-gap: 12px;
      }
      a {
        display: flex;
        align-items: center;
        height: 21px;
        background-color: global.$color-yellow;
        padding-left: 12px;
        padding-right: 12px;
        font-size: 1.3rem;
        border-radius: 21px;
      }
    }
  }
  .socials {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 24px;
    @include global.mq(md) {
      margin-top: 24px;
      justify-content: center;
    }
  }
}

#page_body,
#news_body {
  font-size: 1.6rem;
  line-height: 2;
  word-break: break-all;
  @include global.mq(md) {
    font-size: 1.4rem;
  }
  > * {
    margin-bottom: 48px;
    @include global.mq(md) {
      margin-bottom: 36px;
    }
  }
  a {
    color: black;
    text-decoration: underline;
  }
  h1 {
    margin-bottom: 46px;
  }
  strong,
  b {
    font-weight: 700;
  }
  .yellow_box,
  blockquote {
    background-color: global.$color-light-yellow;
    padding: 24px;
    border-radius: 5px;
    > * {
      &:not(:last-of-type) {
        margin-bottom: 24px;
        @include global.mq(md) {
          margin-bottom: 36px;
        }
      }
    }
  }
  img {
    max-width: 100%;
    height: auto;
    display: inline-block;
  }
  .image_with_comment {
    figcaption {
      margin-top: 12px;
      text-align: center;
      font-size: 1.2rem;
    }
  }
  h2 {
    font-size: 2rem;
    font-weight: 700;
    @include global.mq(md) {
      font-size: 1.6rem;
    }
  }
  h3 {
    font-size: 1.6rem;
    font-weight: 700;
    @include global.mq(md) {
      font-size: 1.4rem;
    }
  }
  table {
    width: 100%;
    border: 1px solid #CCC;
    border-collapse: collapse;
    th,
    td {
      border: 1px solid #CCC;
      padding: 12px 24px;
      @include global.mq(md) {
        font-size: 1.3rem;
        padding: 12px;
      }
    }
    th {
      width: 30%;
      background-color: global.$color-light-yellow;
      font-weight: bold;
    }
    td {
      width: 70%;
    }
  }
  ul {
    margin-left: 1em;
    li {
      list-style: square outside;
    }
  }
  .gallery {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 2em;
    row-gap: 2em;
    flex-wrap: wrap;
    .images {
      flex-basis: 20%;
      @include global.mq(md) {
        flex-basis: 33%;
      }
    }
    p {
      text-align: center;
      font-size: 1.3rem;
    }
  }
  .profile_box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 24px;
    .profile_image {
      flex-basis: 160px;
      @include global.mq(md) {
        flex-basis: 80px;
      }
    }
    figcaption {
      flex: 1;
      position: relative;
    }
    .profile_name {
      font-size: 2rem;
      line-height: 1;
      margin-bottom: 24px;
      @include global.mq(md) {
        font-size: 1.6rem;
        margin-bottom: 12px;
      }
    }
    .profile_pos {
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 1;
      color: #999;
      margin-bottom: 24px;
      @include global.mq(md) {
        margin-bottom: 12px;
        line-height: 1.5;
      }
    }
    .accordion-btn {
      background-color: #FFEF00;
      bottom: 0;
      color: #000;
      cursor: pointer;
      display: inline-block;
      font-size: 18px;
      left: 0;
      margin: auto;
      padding: 0 24px;
      position: absolute;
      right: 0;
      text-align: center;
      transition: all .2s;
      width: fit-content;
      z-index: 1;
      font-size: 1.3rem;
      line-height: 30px;
      border-radius: 30px;
      &::after {
        content: "続きを読む";
      }
      &.is-show {
        bottom: -3em;
        & + .accordion-text::before {
          display: none;
        }
        &::after {
          content: "閉じる";
        }
      }
    }
    .profile_text {
      position: relative;
      overflow: hidden;
      font-size: 1.3rem;
      line-height: 1.5;
      &::before {
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,.9) 50%, rgba(255,255,255,.9) 50%, #fff 100%);
        background:         linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,.9) 50%, rgba(255,255,255,.9) 50%, #fff 100%);
        bottom: 0;
        content: "";
        height: 60px;
        position: absolute;
        width: 100%;
      }
      &.is-hide {
        height: 80px;
      }
      @include global.mq(md) {
        font-size: 1.2rem;
      }
    }
  }
}

#news_footer {
  border-top: 1px solid #CCC;
  padding-top: 48px;
}

#news_relative_entries {
  padding-top: 60px;
  padding-bottom: 60px;
  @include global.mq(md) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  h2 {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 48px;
    @include global.mq(md) {
      font-size: 1.6rem;
    }
    &::after {
      content: '';
      flex: 1;
      background-color: #CCC;
      height: 1px;
    }
  }
  #news_relative_container {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    column-gap: 24px;
    row-gap: 48px;
    flex-wrap: wrap;
    @include global.mq(md) {
      row-gap: 0;
    }
  }
}

#memeber_recruit {

  .container {
    background-color: #fff226;
    padding: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 60px;
    @include global.mq(md) {
      display: block;
      padding: 24px;
    }
    img {
      display: block;
      @include global.mq(md) {
        width: 100%;
      }
    }
    .memeber_recruit {
      flex: 1;
      @include global.mq(md) {
        margin-top: 24px;
      }
      h3 {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 36px;
        @include global.mq(md) {
          font-size: 1.6rem;
          margin-bottom: 24px;
        }
      }
      ul {
        margin-bottom: 36px;
        @include global.mq(md) {
          margin-bottom: 24px;
        }
        li {
          font-size: 1.4rem;
          @include global.mq(md) {
            font-size: 1.2rem;
          }
        }
      }
      .more {
        a {
          display: inline-flex;
          font-size: 1.4rem;
          align-items: center;
          height: 26px;
          background-color: #fff;
          border-radius: 26px;
          padding-left: 12px;
          padding-right: 12px;
          &::before {
            font-family: 'Material Icons';
            content: "\e409";
            font-size: 1.4rem;
          }
          span {
            display: block;
            flex: 1;
          }
        }
      }
    }
  }
}
