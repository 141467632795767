@use "../global";

#category_select {
  margin-top: 48px;
  form {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 24px;
    label {
      font-size: 1.6rem;
      line-height: 1;
      font-weight: bold;
      @include global.mq(md) {
        font-size: 1.4rem;
      }
    }
    .select {
      border: 1px solid #CCC;
      border-radius: 5px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 12px;
      @include global.mq(md) {
        padding: 6px 8px;
        flex: 1;
      }
      select {
        font-size: 1.4rem;
        width: 100%;
      }
      &:after {
        font-family: 'Material Icons';
        content: "\e5cf";
        font-size: 2rem;
        line-height: 1;
      }
    }
  }
}
