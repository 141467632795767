@use "../global";

.container {
  width: global.$pc_width;
  margin-left: auto;
  margin-right: auto;
  @include global.mq(md) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 24px;
    padding-right: 24px;
  }
}
