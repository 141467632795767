@use "../global";

.card_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 36px;
  row-gap: 48px;
  @include global.mq(md) {
    display: block;
  }
}

.card {
  display: block;
  width: 360px;
  @include global.mq(md) {
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 15px;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  .img {
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
    @include global.mq(md) {
      width: 100px;
    }
  }
  img {
    aspect-ratio: 368 / 207;
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .card_detail {
    @include global.mq(md) {
      flex: 1;
    }
  }
  date {
    color: #555;
    font-size: 1.4rem;
    line-height: 1;
  }
  .category {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 24px;
    column-gap: 36px;
    span {
      color: global.$color-red;
      font-size: 1.3rem;
      line-height: 1;
    }
  }
  h3 {
    margin-top: 12px;
    font-size: 1.4rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    @include global.mq(md) {
      font-size: 1.4rem;
    }
  }
}

.work_card {
  display: flex;
  align-items: flex-start;
  column-gap: 48px;
  .img {
    flex-basis: 360px;
    height: 216px;
    img {
      aspect-ratio: 368 / 207;
      display: block;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .card_detail {
    flex: 1;
  }
  date {
    display: block;
    color: #555;
    font-size: 1.4rem;
    line-height: 1;
    margin-bottom: 24px;
  }
  h3 {
    font-size: 2rem;
    margin-bottom: 24px;
  }
  .description {
    font-size: 1.4rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    margin-bottom: 24px;
  }
  .category {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 36px;
    span {
      color: global.$color-red;
      font-size: 1.3rem;
      line-height: 1;
    }
  }
}
