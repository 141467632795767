@use "../global";

html {
	overflow-x:hidden;
	overflow-y:auto;
	font-size: 62.5%;
}


body {
	-webkit-text-size-adjust: 100%;
	font-family: "Helvetica Neue",Helvetica,Arial,"YuGothic","ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ","Meiryo",sans-serif;
	font-size: 10px;
	font-size: 1.0rem;
	line-height: 1.5;
	position: relative;
	color: black;
	padding: 0;
	margin: 0;
	box-sizing: border-box;
  letter-spacing: normal;
	font-weight: normal;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-feature-settings: "palt";
	font-feature-settings: "palt";
  text-justify: inter-ideograph;
	&.-fixed {
		@include global.mq(md) {
			position: static;
		}
	}
}
