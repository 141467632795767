@use "../global";

h1 {
  font-size: 3.6rem;
  line-height: 1.2;
  font-weight: 700;
  text-align: center;
  @include global.mq(md) {
    font-size: 2rem;
  }
}

#section_h2 {
  font-size: 3.2rem;
  font-weight: 700;
  margin-bottom: 48px;
  font-family: 'Raleway', sans-serif;
  @include global.mq(md) {
    font-size: 1.6rem;
  }
}
