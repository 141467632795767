@use "../global";

#about_image {
  img {
    display: block;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    @include global.mq(md) {
      height: 230px;
    }
  }
  @include global.mq(md) {
    &.container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

#about_container {
  padding-top: 60px;
  padding-bottom: 60px;
  @include global.mq(md) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  h1 {
    margin-bottom: 48px;
  }
  h2 {
    font-weight: 700;
    font-size: 2.4rem;
    display: flex;
    align-items: center;
    column-gap: 48px;
    margin-bottom: 48px;
    @include global.mq(md) {
      font-size: 1.6rem;
      column-gap: 24px;
    }
    &::after {
      content: '';
      height: 1px;
      width: 96px;
      background-color: #CCC;
      @include global.mq(md) {
        width: auto;
        flex: 1;
      }
    }
  }
}

#about_bland {
  background-color: #f6e924;
  background-image: url(../img/bg_about.webp);
  background-repeat: repeat-x;
  background-size: contain;
  background-position: center;
  height: 228px;
  @include global.mq(md) {
    background-image: url(../img/bg_about_sp.webp);
    height: 258px;
  }
}

#about_concept {
  position: relative;
  @include global.mq(md) {
    overflow: hidden;
  }
}

.about_concept {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 60px;
  margin-bottom: 48px;
  @include global.mq(md) {
    display: block;
  }
  > img {
    display: block;
    @include global.mq(md) {
      margin-bottom: 24px;
    }
  }
  #dummy_left {
    width: 300px;
  }
  .article {
    flex: 1;
  }
  p {
    font-size: 1.8rem;
    line-height: 2;
    &:not(:last-child) {
      margin-bottom: 48px;
    }
    @include global.mq(md) {
      font-size: 1.4rem;
      &:not(:last-child) {
        margin-bottom: 28px;
      }
    }
  }
}

#about_executive {
  padding-top: 60px;
  padding-bottom: 60px;
  h2 {
    font-weight: 700;
    font-size: 2.4rem;
    display: flex;
    align-items: center;
    column-gap: 48px;
    margin-bottom: 48px;
    @include global.mq(md) {
      font-size: 1.6rem;
      column-gap: 24px;
    }
    &::after {
      content: '';
      height: 1px;
      width: 96px;
      background-color: #CCC;
      @include global.mq(md) {
        width: auto;
        flex: 1;
      }
    }
  }
}

#about_executive_container {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 48px;
  @include global.mq(md) {
    display: block;
  }
}

.about_executive {
  flex-basis: 368px;
  @include global.mq(md) {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  .executive_container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 24px;
    @include global.mq(md) {
      column-gap: 12px;
    }
  }
  img {
    display: block;
    width: 160px;
    @include global.mq(md) {
      width: 120px;
    }
  }
  .name {
    font-size: 2rem;
    margin-bottom: 24px;
    @include global.mq(md) {
      font-size: 1.6rem;
      margin-bottom: 12px;
    }
  }
  .position {
    font-size: 1.2rem;
    color: #999;
    font-weight: 700;
    margin-bottom: 24px;
    @include global.mq(md) {
      margin-bottom: 12px;
    }
  }
  .executive_profile {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 1.2rem;
    @include global.mq(md) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .category {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    column-gap: 12px;
    span {
      font-size: 1.2rem;
      line-height: 24px;
      background-color: global.$color-yellow;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

#about_overview {
  background-color: #232323;
  padding-top: 90px;
  padding-bottom: 90px;
  color: #ccc;
  @include global.mq(md) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  h2 {
    font-weight: 700;
    font-size: 2.4rem;
    display: flex;
    align-items: center;
    column-gap: 48px;
    margin-bottom: 48px;
    @include global.mq(md) {
      font-size: 1.6rem;
      column-gap: 24px;
    }
    &::after {
      content: '';
      height: 1px;
      width: 96px;
      background-color: #CCC;
      @include global.mq(md) {
        width: auto;
        flex: 1;
      }
    }
  }
  #about_overview_container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    column-gap: 60px;
    padding-top: 24px;
    @include global.mq(md) {
      display: block;
    }
    .about_overview {
      flex: 1;
      @include global.mq(md) {
        &:not(:last-of-type) {
          margin-bottom: 60px;
        }
      }
      h3 {
        display: flex;
        align-items: center;
        column-gap: 24px;
        font-size: 3.2rem;
        line-height: 1;
        font-weight: 700;
        margin-bottom: 24px;
        @include global.mq(md) {
          font-size: 2.2rem;
        }
      }
      h4 {
        font-size: 2rem;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 24px;
        background-color: #000;
        line-height: 40px;
        text-align: center;
        border: 1px solid #BE8900;
        border-radius: 40px;
        @include global.mq(md) {
          font-size: 1.6rem;
          line-height: 30px;
        }
      }
      p {
        font-size: 1.6rem;
        @include global.mq(md) {
          font-size: 1.4rem;
        }
      }
    }
  }
}
